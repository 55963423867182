import React from 'react';
import { Link } from 'react-router-dom';

const Navigation = () => {
  return (
    <nav>
      <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
        <div className='hidden sm:ml-6 sm:flex sm:space-x-8 content-center border-black border-solid'>
          <Link className="inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900" to="/">Home</Link>
          <Link className="inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900" to="/about">About</Link>
          <Link className="inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900" to="/resume">Resume</Link>
          <Link className="inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900" to="/contact">Contact</Link>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;