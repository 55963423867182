import Navigation from "../shared/navigation"
import { useQuery, gql } from '@apollo/client';

const GET_CONTENT = gql`
query Content {
  page(where: {slug: "resume"}) {
    content {
      html
    }
  }
}`;

export default function Resume() {
  const { loading, error, data } = useQuery(GET_CONTENT);

  if(loading) return <p>Loading...</p>;
  if(error) {
    console.log("Error: ", error);
    return <p>Error: </p>;
  }

  return (
    <>
      <Navigation />
      <main>
        <h1>
          My Resume
        </h1>
        <div dangerouslySetInnerHTML={{ __html: data.page.content.html }} />
      </main>
    </>
  )
}