import Navigation from '../shared/navigation';

export default function About() {
  return (
    <div>
      <Navigation />
      <h1>
        About Me
      </h1>
    </div>
  )
}