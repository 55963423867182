import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import About from './routes/about';
import Resume from './routes/resume';
import Navigation from './shared/navigation';
import Contact from './routes/contact';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql, HttpLink, ApolloLink } from '@apollo/client';
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";


const httpLink = new HttpLink({
  uri: 'https://api-us-east-1-shared-usea1-02.hygraph.com/v2/clfx6xvn95zow01ui5bvph2x3/master'
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2ODgyNTIxODksImF1ZCI6WyJodHRwczovL2FwaS11cy1lYXN0LTEtc2hhcmVkLXVzZWExLTAyLmh5Z3JhcGguY29tL3YyL2NsZng2eHZuOTV6b3cwMXVpNWJ2cGgyeDMvbWFzdGVyIiwibWFuYWdlbWVudC1uZXh0LmdyYXBoY21zLmNvbSJdLCJpc3MiOiJodHRwczovL21hbmFnZW1lbnQuZ3JhcGhjbXMuY29tLyIsInN1YiI6IjhkYWUxNzJhLTc3MzUtNDFkYS1hYWZkLTM3NTZmZDdiZTc3NCIsImp0aSI6ImNsamtscmloMzE5aXowMXRiMjV5NzljMXQifQ.Lh9fD8_XTeQp4ImCH0NoNJcjUwn_6vCertpJwohVbK_ZwH_yVm56PFP0JE5YEFY9Xi7w5-5ZVzqQ84nSrC3Z12Axcgl-W-shshmgeYuOCZIPXKFel1MffmELbimBzibkK9Z7idc29wpBDQYB1Wv5sNulGa-iMRkuSFwTQER2rVMVFB85nPAsbBgX_uMfpnYNNkieQEu6zm8pLwamDJU7dtR0kOBz46LHcLSCzaK4AcjsSHOdqPrAal_GeAvZrjzAz9Zo_QX39VQF9UWKK2t0UlKZSacWOMZFWriBfho3pmYAIYCxoUZHNscgVeeXKMo8qWUQxSOmBOtVyQiIPkEHBwmwYdyBodK_Rwbqhwet7wQjprWZ1XJIf0MrGjlfv4csZ9uZlsFEcWIVZdLgwsajY8d2IupSFpo38rGRBXNHCe0Lm6pNXwG8qctq5R76z-IK9Zytkzt9dXslyCMprIz-Ftt2iwBiF5PDU3IxsyyGkmuaz1WLwtrYfHhWID_EEZNrSvRy4TdoCz9ZJAlHv8iS9vfFnkcGq2_nFTdl9vZlhvX8d2ZpHoqZka91YvHeSU2oBjlu0LZyHQAY9Ia1hlKQCQGWH0dgJr27B1cQ4UJa4Xe94_4PJ6jS-oXN-KALiPaJveJFLbHdfLVBPmabHG6BdDawG4tmqLcmwtHpSbjYPlM',
    },
  });
  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const GET_CONTENT = gql`
query Content {
  page(where: {slug: "home"}) {
    content {
      html
    }
  }
}`;

function Home() {
  const { loading, error, data } = useQuery(GET_CONTENT);

  if(loading) return <p>Loading...</p>;
  if(error) {
    console.log("Error: ", error);
    return <p>Error: </p>;
  }

  console.log("data: ", data);
  return(
      <div>

<Navigation />

<div className="bg-white">
  <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
    <div className="mx-auto max-w-7xl px-6 pt-16 sm:py-16 lg:px-8">
      <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-8 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
        <h1 className="max-w-2xl text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl lg:col-span-2 xl:col-auto">
          Hi there,<br/>
          Welcome to Kalea's website.
        </h1>
        <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
          <p className="text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
          Kalea is a student with a passion for music, scouting, and soccer. She's an all-around talented individual with a bubbly personality and a heart full of ambition.
          </p>
          <div className="mt-10 flex items-center gap-x-6">
            <Link className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" to="/resume">
              View Resume
            </Link>
            <Link className="text-sm/6 font-semibold text-gray-900" to="about">
              More about Kalea <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
        <img
          alt=""
          src="https://media.graphassets.com/IsCfhEUR1acsB2prvxRf"
          className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
        />
      </div>
    </div>
    <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
    </div>
  </div>
</div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="resume" element={<Resume />} />
        <Route path="contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
