import Navigation from "../shared/navigation"
import ContactForm from "../components/contact_form"

export default function Contact() {
  return (
    <div>
      <Navigation />
      <h1>
        Contact
      </h1>
      <ContactForm />
    </div>
  )
}